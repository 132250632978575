import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SEO, NotFound } from 'components';

const NotFoundPage = ({ location: { pathname } }) => (
  <Fragment>
    <SEO title="404: Not found" pathname={pathname} />
    <NotFound />
  </Fragment>
);

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default NotFoundPage;
